body {
  overflow-y: scroll;
  @apply text-slate-600;
}

p {
  @apply mb-4;
  @apply text-lg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-slate-800;
  @apply font-bold;
}

form input,
form select,
form textarea {
  @apply w-full;
  @apply border border-slate-200;
  @apply rounded;
  @apply p-3;
}

form label {
  @apply uppercase;
  @apply tracking-wider;
  @apply font-bold;
  @apply text-xs;
  @apply text-slate-500;
}

form input,
form select {
  @apply h-12;
}

form textarea {
  @apply h-24;
}
